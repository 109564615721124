import { User } from '@/api';
import { createContext } from 'react';

export type UserContextType = { user: User | null, setUser: (user: User | null) => void };

const UserContext = createContext<UserContextType>({
    user: null,
    setUser: (user: User | null) => {}
});

export default UserContext;
