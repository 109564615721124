import { ImgHTMLAttributes } from 'react';

export function ResponsiveImage(props: ImgHTMLAttributes<HTMLImageElement> & { imageWidth?: number }) {
    const { src, imageWidth, ...rest } = props;
    if (src && imageWidth) {
        // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
        return <img {...rest} src={`${src}?dw=${imageWidth}`} srcSet={`${src}?dw=${imageWidth * 2} 2x`} />;
    }
    // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
    return <img src={src} {...rest} />;
}
