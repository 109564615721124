import { Auction } from '@/api';
import * as uuid from 'uuid';

const uuidv4 = uuid.v4;

export type AuctionChangedListener = (auction: Auction) => void;

export default class AuctionManager {
    private static instance: AuctionManager;

    private static cache: { [key: string]: Auction } = {};

    public static getInstance(): AuctionManager {
        if (AuctionManager.instance) {
            return AuctionManager.instance;
        }
        AuctionManager.instance = new AuctionManager();
        return AuctionManager.getInstance();
    }

    private _auctionIdStateListeners: {
        [key: string]: { [key: string]: AuctionChangedListener };
    } = {};

    static getMostRecentAuction(auction: Auction) {
        if (AuctionManager.cache[auction.id]) {
            if (
                new Date(auction.updated_at) <=
                new Date(AuctionManager.cache[auction.id].updated_at)
            ) {
                return AuctionManager.cache[auction.id];
            }
        }
        return auction;
    }

    public updateAuction(auction: Auction) {
        AuctionManager.cache[auction.id] = auction;
        this.callAuctionStateListeners(auction);
    }

    public addAuctionStateListener(
        auction: Auction | undefined,
        listener: AuctionChangedListener
    ): string {
        const id = uuidv4();
        if (!auction) {
            return id;
        }
        const listenersObj: { [key: string]: AuctionChangedListener } = {};
        listenersObj[id] = listener;
        this._auctionIdStateListeners[auction.id] = {
            ...(this._auctionIdStateListeners[auction.id] || {}),
            ...listenersObj,
        };
        if (AuctionManager.cache[auction.id]) {
            if (
                new Date(auction.updated_at) <
                new Date(AuctionManager.cache[auction.id].updated_at)
            ) {
                listener(AuctionManager.cache[auction.id]);
            } else {
                AuctionManager.cache[auction.id] = auction;
            }
        } else {
            AuctionManager.cache[auction.id] = auction;
        }
        return id;
    }

    public removeAuctionStateListener(
        auction: Auction | undefined,
        id: string
    ) {
        if (!auction) {
            return;
        }
        if (this._auctionIdStateListeners[auction.id]) {
            delete this._auctionIdStateListeners[auction.id][id];
        }
    }

    private callAuctionStateListeners(auction: Auction) {
        Object.keys(this._auctionIdStateListeners[auction.id] || {})
            .map(
                (key) => (this._auctionIdStateListeners[auction.id] || {})[key]
            )
            .forEach((data) => {
                const cb = data;
                cb(auction);
            });
    }
}
