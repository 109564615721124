'use client';

import UserContext, { UserContextType } from '@/contexts/UserContext';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { authApi, userApi } from '@/queries/auth';
import { Constants } from '@/helpers/Constants';
import { CookiesHelper } from '@/helpers/CookiesHelper';
import dayjs from 'dayjs';

export type AuthContextType = UserContextType & {
    logOut: () => void,
    checkToken: (forceRefresh?: boolean, loadUser?: boolean) => Promise<boolean>,
    updateRefreshToken: (token: string) => void
};

const AuthContext = createContext<AuthContextType>({
    user: null,
    setUser: () => {},
    logOut: () => {},
    checkToken: async () => false,
    updateRefreshToken: () => {},
});

const useProvideAuth = () => {
    const { user, setUser } = useContext(UserContext);
    const refreshTokenTimeout = useRef<NodeJS.Timeout>();

    const logOut = useCallback(() => {
        return authApi.authControllerLogout().catch(() => null).then(() => {
            localStorage.removeItem(Constants.Auth.KEY_REFRESH_TOKEN);
            CookiesHelper.unsetCookie(Constants.Auth.KEY_COOKIE_NAME);
            setUser(null);
            if (refreshTokenTimeout.current) {
                clearTimeout(refreshTokenTimeout.current);
            }
            return null;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scheduleRefresh = useCallback((expirationDate: Date) => {
        const now = new Date();
        const twoMinutesInMillis = 2 * 60 * 1000;
        const reminderTime = new Date(
            expirationDate.getTime() - twoMinutesInMillis
        );
        const timeUntilReminder = reminderTime.getTime() - now.getTime();
        if (refreshTokenTimeout.current) {
            clearTimeout(refreshTokenTimeout.current);
        }
        refreshTokenTimeout.current = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            checkToken(true);
        }, timeUntilReminder);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkToken = useCallback(async (forceRefresh = false, loadUser = true) => {
        const jwtExpiration = CookiesHelper.extractTokenExpirationDate(
            Constants.Auth.KEY_COOKIE_NAME
        );
        if (jwtExpiration || forceRefresh) {
            if (dayjs(jwtExpiration).isBefore(dayjs()) || forceRefresh) {
                const token = localStorage.getItem(
                    Constants.Auth.KEY_REFRESH_TOKEN
                );
                if (token) {
                    try {
                        await authApi.authControllerRefresh({
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        const newJwtExpiration = CookiesHelper.extractTokenExpirationDate(Constants.Auth.KEY_COOKIE_NAME);
                        if (newJwtExpiration) {
                            scheduleRefresh(newJwtExpiration);
                        }
                    } catch (error) {
                        localStorage.removeItem(Constants.Auth.KEY_REFRESH_TOKEN);
                        logOut();
                        return false;
                    }
                } else {
                    logOut();
                    return false;
                }
            } else if (jwtExpiration) {
                scheduleRefresh(jwtExpiration);
            }
            if (loadUser) {
                try {
                    const res = await userApi.userControllerMe();
                    if (res) {
                        setUser(res);
                    }
                } catch (error) {
                    logOut();
                    return false;
                }
            }
            return true;
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user?.id) {
            checkToken(false, false);
        }
    }, [user?.id, checkToken]);

    const updateRefreshToken = (token: string) => {
        localStorage.setItem(Constants.Auth.KEY_REFRESH_TOKEN, token);
    };

    return { user, setUser, logOut, checkToken, updateRefreshToken };
};

export const AuthProvider = ({ children }: { children: React.ReactNode; }) => {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default useAuth;
