import {
    AuthApi,
    ChangePasswordDto,
    EmailDto,
    EmailSignInDto,
    EmailSignUpDto,
    Enable2FADto,
    GoogleSignUpDto,
    Request2FADto,
    ResetPasswordDto,
    UserApi,
    VerifyEmailDto,
} from '@/api';
import { apiConfiguration } from '@/api/config';

export type LoginQueryData = {
    body: EmailSignInDto;
};

export type SignUpQueryData = {
    body: EmailSignUpDto;
};

export const authApi = new AuthApi(apiConfiguration);

export const userApi = new UserApi(apiConfiguration);

export const signUpQueryFn = async (data: SignUpQueryData) => {
    return authApi.authControllerSignUp(
        {
            ...data.body,
            device: data.body.device,
        }
    );
};

export const loginQueryFn = async (data: LoginQueryData) => {
    return authApi.authControllerSignInEmail(
        {
            email_address: data.body.email_address,
            password: data.body.password,
            device: data.body.device,
        }
    );
};

export const googleLoginQueryFn = async (data: GoogleSignUpDto) => {
    return authApi.authControllerSignInGoogle(data);
};

export const forgotPassQueryFn = async (data: EmailDto) => {
    return authApi.authControllerForgotPassword({
        email_address: data.email_address,
    });
};

export const resetPassQueryFn = async (data: ResetPasswordDto) => {
    return authApi.authControllerResetPasswordByToken(data);
};

export const verifyAccountQueryFn = async (data: VerifyEmailDto) => {
    return authApi.authControllerVerifyEmail(data);
};
export const requestVerificationEmailFn = async () => {
    return authApi.authControllerResendVerification();
};

export const getMeQueryFn = async () => {
    return userApi.userControllerMe();
};

export const resendOtpQueryFn = async () => {
    return authApi.authControllerResendMfa();
};

export const verifyOtpQueryFn = async (code: string) => {
    return authApi.authControllerVerify2FACode({
        code,
    });
};

export const enable2FAQueryFn = async (data: Enable2FADto) => {
    return authApi.authControllerEnable2FACode(data);
};

export const disable2FAQueryFn = async () => {
    return authApi.authControllerDisable2FACode();
};

export const request2FAQueryFn = async (data: Request2FADto) => {
    return authApi.authControllerRequest2FACode(data);
};

export const changePasswordFn = async (changeDto: ChangePasswordDto) => {
    return authApi.authControllerChangePassword(changeDto);
};
