import { userApi } from './auth';

export const addToFavoritesQueryFn = async (id: string) => {
    return userApi.userControllerAddToFavorites(id);
};

export const removeFromFavoritesQueryFn = async (id: string) => {
    return userApi.userControllerRemoveFromFavorites(id);
};

export const getFavoritesQueryFn = async () => {
    return userApi.userControllerFavorites();
};
