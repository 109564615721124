import clsx from 'clsx';
import React from 'react';
import NumericLabel from 'react-pretty-numbers';

export type IPriceProps = {
    number: string;
    justification?: 'L' | 'R' | 'C';
    state?: 'positive' | 'negative' | null;
    precision?: number;
    className?: string;
};

const Price = (props: IPriceProps) => {
    const { number, justification = 'L', className, state = null, precision = 0 } = props;
    return (
        <div className={clsx(className, state ? state === 'positive' ? 'text-green3' : 'text-red' : null)}>
            <NumericLabel params={{ precision, currency: true, justification }}>
                {number}
            </NumericLabel>
        </div>
    );
};

export default Price;
