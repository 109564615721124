import { API_URL } from '@/api/config';
import useAuth from '@/contexts/AuthContext';
import { useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { singletonHook } from 'react-singleton-hook';

const useSocket = () => {
    const { user } = useAuth();
    const [socket, setSocket] = useState<Socket>();

    useEffect(() => {
        if (user && user.socket_eligible) {
            const socketIo = io(API_URL, { withCredentials: true, autoConnect: true, reconnectionDelay: 800 });
            socketIo.on('connect', () => {
                setSocket(socketIo);
            });
            return () => {
                setSocket(undefined);
                socketIo.disconnect();
            };
        }
    }, [user]);
    return socket;
};

const singletonSocketHook = singletonHook(undefined, useSocket);

export default singletonSocketHook;
